:root {
  --primary-color: #5b6df3;
  --primary-hover: #4351be;
  --secondary-color: #46aefa;
  --white-color: #fff;
  --black-color: #000;
  --link-color: #608dff;
  --yellow-color: #ff9b00;

  //gray
  --gray9-color: #424242;
  --gray8-color: #616161;
  --gray7-color: #757575;
  --gray6-color: #9e9e9e;
  --gray5-color: #bdbdbd;
  --gray4-color: #e0e0e0;
  --gray3-color: #eeeeee;
  --gray2-color: #f5f5f5;
  --gray1-color: #fafafa;
  --gray-light: #97adb6;

  //pink
  --pink9-color: #ae0071;
  --pink8-color: #ae0071;
  --pink7-color: #dd007e;
  --pink6-color: #ef0082;
  --pink5-color: #ef0099;
  --pink4-color: #fc58a5;
  --pink3-color: #f086c7;
  --pink2-color: #f4b7de;
  --pink1-color: #fbe2f1;

  //action
  --success-color: #26b470;
  --error-color: #f03738;
  --warning-color: #ffc104;
  --purple-color: #796eff;

  --gradient-primary: linear-gradient(
    180deg,
    #ef4eae 34.69%,
    #ef53a3 56.04%,
    #ef7e4e 100%
  );
  --gradient1: linear-gradient(291.83deg, #ff9746 1.84%, #ffca51 97.38%);
  --gradient2: linear-gradient(
    134.97deg,
    #ef4eae 0%,
    #ef6287 40.96%,
    #ef7e4e 100.06%
  );
  --gradient3: linear-gradient(
    180deg,
    #ef4eae 34.69%,
    #ef53a3 56.04%,
    #ef7e4e 100%
  );

  //shadow
  --shadow-color: 0px -8px 10px rgba(0, 0, 0, 0.1);
  --shadow1-color: 0px 8px 10px rgba(0, 0, 0, 0.1);
  --shadow2-color: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  --shadowpink-color: 0px 0px 10px rgba(239, 78, 174, 0.6);

  //font
  --font-quicksand: "Quicksand";
  --font-poppins: "Poppins";
}
